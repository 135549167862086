<template>
  <el-form
    :inline="inline"
    :model="data"
    status-icon
    :rules="rules"
    label-position="top"
    :validate-on-rule-change="false"
    ref="ruleForm"
  >
    <!-- {{ list }´} -->
    <!-- <p>{{ rules }}</p> -->
    <!-- <p>{{ hiddenNameList }}</p> -->
    <template v-for="(item, index) in list">
      <!-- {{ hiddenNameList.includes(item.name) }} -->
      <el-divider
        v-if="item.type === 'title'"
        content-position="left"
        :key="`key_${index}`"
        >{{ $t(item.label) }}</el-divider
      >
      <el-form-item
        v-else
        v-show="!hiddenNameList.includes(item.name)"
        :prop="item.name"
        :label="$t(item.label)"
        :key="`key_${index}`"
      >
        <template v-if="item.type === 'mobile'">
          <el-col :span="7">
            <el-select v-model="data.phoneCode">
              <el-option
                v-for="item in phoneCodeJson"
                :key="item.id"
                :label="
                  /*$t('categorys.' + item.nicename)很多国家名称没对称，延后处理*/
                  item.nicename + ' +' + item.phonecode
                "
                :value="item.phonecode"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="9">
            <el-input
              v-model.trim="data[item.name]"
              autocomplete="autocomplete"
              style="margin-left: 10px"
            ></el-input>
          </el-col>
        </template>
        <template v-else-if="item.type === 'uploadimage'">
          <upload-image-list v-model="data[item.name]" :item="item" />
        </template>
        <template v-else-if="item.type === 'uploadimagenotoken'">
          <upload-image-list-notoken v-model="data[item.name]" :item="item" />
        </template>
        <template v-else-if="item.type === 'radio'">
          <el-radio-group
            v-model="data[item.name]"
            @change="onChange($event, item.setHidden)"
          >
            <el-radio
              v-for="(rangeItem, rangeIndex) in item.range"
              :key="`rangeIndex-${rangeIndex}`"
              :label="rangeItem.value"
            >
              <i
                v-if="rangeItem.icon"
                class="sokogate"
                :class="rangeItem.icon"
              />
              {{ $t(rangeItem.label) }}
            </el-radio>
          </el-radio-group>
        </template>
        <template v-else-if="item.type === 'select'">
          <el-col>
            <el-select
              v-model="data[item.name]"
              :placeholder="$t('common.selectPlaceholder')"
              autocomplete="autocomplete"
            >
              <el-option
                v-for="(rangeItem, rangeIndex) in item.range"
                :key="`selectRangeIndex-${rangeIndex}`"
                :value="rangeItem.value"
                :label="rangeItem.t ? $t(rangeItem.label) : rangeItem.label"
              >
              </el-option>
            </el-select>
          </el-col>
        </template>
        <template v-else-if="item.type === 'switch'">
          <el-col>
            <el-switch
              v-model="data[item.name]"
              :active-value="1"
              :inactive-value="0"
              @change="onChange($event, item.setHidden)"
            >
            </el-switch>
          </el-col>
        </template>
        <template v-else-if="!!components[item.type]">
          <component
            v-bind:is="components[item.type]"
            v-model="data[item.name]"
            :item="item"
            :formData="data"
            :autocomplete="'new-password'"
            :changeVal="changeVal == 101"
            :pagePath="pagePath"
          ></component>
        </template>
        <el-col v-else>
          <el-input
            v-if="item.type === 'password'"
            :type="item.type"
            v-model.trim="data[item.name]"
            :placeholder="$t(item.label)"
            autocomplete="new-password"
            :show-password="true"
          ></el-input>
          <el-input
            v-else-if="item.type === 'mail'"
            :type="item.type"
            :placeholder="$t(item.label)"
            v-model.trim="data[item.name]"
            autocomplete="autocomplete"
          ></el-input>
          <el-input
            v-else-if="item.type === 'number'"
            :type="item.type"
            :placeholder="$t(item.label)"
            v-model.number="data[item.name]"
            autocomplete="autocomplete"
          ></el-input>
          <price-input
            :placeholder="item.label"
            v-model.number="data[item.name]"
            v-else-if="item.type === 'price'"
          />
          <quantityofabox-input
            :placeholder="item.label"
            v-model.number="data[item.name]"
            v-else-if="item.type === 'quantityofabox'"
          />
          <el-input
            v-else
            :type="item.type"
            :placeholder="$t(item.label)"
            v-model="data[item.name]"
            autocomplete="autocomplete"
          ></el-input>
        </el-col>
        <tips v-if="item.tips" :text="$t(item.tips)" />
      </el-form-item>
    </template>
    <el-form-item class="form-item-flex">
      <el-button
        type="primary"
        @click="submitForm('ruleForm')"
        :style="submitWidth && { width: submitWidth + '%', maxWidth: 'none' }"
      >
        {{ $t(submitTitle) }}
      </el-button>
      <el-button v-if="showReset" @click="resetAndSubmit('ruleForm')">
        {{ $t("common.reset") }}
      </el-button>
    </el-form-item>
  </el-form>
</template>
  <script>
import phoneCodeJson from "../../../../static/phonecode.json";
import { metadata, PhoneNumberUtil } from "google-libphonenumber";
const phoneUtil = PhoneNumberUtil.getInstance();
import * as SuiValidate from "@/utils/SuiValidate";
import Tips from "@/components/s-ui/form/Tips.vue";
import UploadImageList from "@/components/s-ui/form/UploadImageList";
import CountrySelect from "@/components/s-ui/form/CountrySelect";
import CategoryCascader from "@/components/s-ui/form/CategoryCascader";
import CategoryCascaderSelf from "@/components/s-ui/form/CategoryCascaderSelf";
import StoreSelect from "@/components/s-ui/form/StoreSelect";
import PriceInput from "@/components/s-ui/form/PriceInput";
import BrandSelect from "@/components/s-ui/form/BrandSelect";
import DictionaryInput from "@/components/s-ui/form/DictionaryInput";
import VolumeInputNumber from "@/components/s-ui/form/VolumeInputNumber";
import ColorInputGroup from "@/components/s-ui/form/ColorInputGroup";
import SizeInputGroup from "@/components/s-ui/form/SizeInputGroup";
import TagInput from "@/components/s-ui/form/TagInput";
import WeightSelect from "@/components/s-ui/form/WeightSelect";
import EmailVerifyCodeInput from "@/components/s-ui/form/EmailVerifyCodeInput";
import CategorySelect from "@/components/s-ui/form/CategorySelect";
import InputNumberDecimalFour from "@/components/s-ui/form/InputNumberDecimalFour";
import InputNumberPriceFour from "@/components/s-ui/form/InputNumberPriceFour";
import ExchangeRateSelect from "@/components/s-ui/form/ExchangeRateSelect";
import CategoryIdSelect from "@/components/s-ui/form/CategoryIdSelect";
import UploadImageListNotoken from "@/components/s-ui/form/UploadImageListNotoken";
import CityCascader from "@/components/s-ui/form/CityCascader";
import goodsTypeList from "@/components/s-ui/form/goodsTypeList";
import ToCountrySelect from "@/components/s-ui/form/ToCountrySelect";
import ToCityCascader from "@/components/s-ui/form/ToCityCascader";
import CountryListSelect from "@/components/s-ui/form/CountryListSelect";
import ToCountryListSelect from "@/components/s-ui/form/ToCountryListSelect";
import CityListCascader from "@/components/s-ui/form/CityListCascader";
import ToCityListCascader from "@/components/s-ui/form/ToCityListCascader";
import QuantityofaboxInput from "@/components/s-ui/form/Quantityofabox";
import LogisticsLadderList from "@/components/s-ui/form/LogisticsLadderList";
export default {
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    defaultdata: {
      type: Object,
      default() {
        return {};
      },
    },
    submitTitle: {
      type: String,
      default: "common.submit",
    },
    submitWidth: {
      type: Number,
      default: 0,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Tips,
    UploadImageList,
    CountrySelect,
    CategoryCascader,
    CategoryCascaderSelf,
    StoreSelect,
    PriceInput,
    BrandSelect,
    DictionaryInput,
    VolumeInputNumber,
    TagInput,
    ColorInputGroup,
    WeightSelect,
    EmailVerifyCodeInput,
    CategorySelect,
    InputNumberDecimalFour,
    InputNumberPriceFour,
    ExchangeRateSelect,
    CategoryIdSelect,
    UploadImageListNotoken,
    CityCascader,
    goodsTypeList,
    ToCountrySelect,
    ToCityCascader,
    CountryListSelect,
    ToCountryListSelect,
    CityListCascader,
    ToCityListCascader,
    QuantityofaboxInput,
    LogisticsLadderList,
  },
  data() {
    return {
      phoneCodeJson: [],
      category: [],
      changeVal: 101,
      pagePath: false,
      components: {
        country: CountrySelect,
        category: CategoryCascader,
        categotyself: CategoryCascaderSelf,
        store: StoreSelect,
        brand: BrandSelect,
        dictionary: DictionaryInput,
        volume: VolumeInputNumber,
        colorgroup: ColorInputGroup,
        sizegroup: SizeInputGroup,
        taglist: TagInput,
        weight: WeightSelect,
        code: EmailVerifyCodeInput,
        categoryselect: CategorySelect,
        boxcbm: InputNumberDecimalFour,
        priceByfour: InputNumberPriceFour,
        currency: ExchangeRateSelect,
        categoryId: CategoryIdSelect,
        city: CityCascader,
        goodsTypeList: goodsTypeList,
        tocountry: ToCountrySelect,
        tocity: ToCityCascader,
        countrylist: CountryListSelect,
        tocountrylist: ToCountryListSelect,
        citylist: CityListCascader,
        tocitylist: ToCityListCascader,
        logisticsladderlist: LogisticsLadderList,
      },
      customHiddenNameList: [],
    };
  },
  created() {
    this.phoneCodeJson = phoneCodeJson;
    // 在注册页面时 修改密码框
    console.log(this.$route.path);
    if (this.$route.path === "/v2/register") {
      console.log("注册", this.list);
      this.list.map((k) => {
        if (k.type === "password") {
          k.type = "text";
        }
      });
    } else if (this.$route.path === "/product/edit") {
      this.pagePath = true;
      const goodsTypeList = localStorage.getItem("goodsTypeList");
      if (goodsTypeList) {
        localStorage.removeItem("goodsTypeList");
        console.log("goodsTypeList", goodsTypeList);
      }
    }
    document.onkeydown = (e) => {
      e = window.event || e;
      // 键盘的回车键进入
      if (e.code == "Enter" || e.code == "enter") {
        this.submitForm();
      }
    };
  },
  mounted() {
    // console.log("Current Route------------1111:", this.$route.path);
    // console.log("computed:", this.data);
  },
  computed: {
    data() {
      return this.defaultdata;
    },
    selectLabel() {
      return this.$store.state.fromCountry;
    },
    shippingPrice() {
      return this.$store.state.shippingPrice;
    },
    channelPrice() {
      return this.$store.state.channelPrice;
    },
    selectToLabel() {
      return this.$store.state.toCountry;
    },
    hiddenNameList() {
      const propsHiddenNameList = this.list
        .filter((v) => v.hidden)
        .map((v) => v.name);
      // console.log("propsHiddenNameList:", propsHiddenNameList);
      return [...propsHiddenNameList, ...this.customHiddenNameList];
    },

    // 验证手机正则
    rules() {
      // eslint-disable-next-line no-unused-vars
      var validateGlobalMobile = (rule, value, callback) => {
        if (value.length < 3) {
          callback(new Error());
        }
        const number = phoneUtil.parseAndKeepRawInput(
          value,
          metadata.countryCodeToRegionCodeMap[this.data.phoneCode][0]
        );
        const isValite = phoneUtil.isValidNumber(number);
        if (isValite) {
          callback();
        } else {
          callback(new Error());
        }
      };
      // 输入密码验证
      // eslint-disable-next-line no-unused-vars
      var validatePass = (rule, value, callback) => {
        if (this.data.confirmpassword !== "") {
          this.$refs.ruleForm.validateField("confirmpassword");
        }
        callback();
      };
      // 两次输入密码验证
      // eslint-disable-next-line no-unused-vars
      var validatePass2 = (rule, value, callback) => {
        if (value === "") {
          callback(new Error());
        } else if (value !== this.data.password) {
          callback(new Error());
        } else {
          callback();
        }
      };
      return this.list
        .filter((v) => v.rules && !this.hiddenNameList.includes(v.name))
        .reduce(
          (a, v) => ({
            ...a,
            [v.name]: v.rules.map((r) => {
              let validator;
              if (r.eval) {
                validator = eval(r.eval);
              } else if (SuiValidate[v.type]) {
                validator = SuiValidate[v.type];
              }
              return {
                ...r,
                validator,
                message: r.message
                  ? this.$t(r.message)
                  : `${this.$t(v.label)}${this.$t("common.isreq")}`,
                trigger: Object.prototype.hasOwnProperty.call(r, "trigger")
                  ? r.trigger
                  : "blur",
              };
            }),
          }),
          {}
        );
    },
    showReset() {
      return !!this.$listeners["reset"];
    },
  },
  methods: {
    onUploadSuccess(value, item) {
      console.log("onUploadSuccess:", item, value);
      if (item.max) {
        const newValue = [...(this.data[item.name] || []), value.filename];
        console.log("newValue:", newValue);
        this.$set(this.data, item.name, newValue);
      } else {
        this.$set(this.data, item.name, value.filename);
      }
    },

    tapUploadDelete(name, item, index) {
      if (item.max) {
        const newValue = this.data[item.name];
        newValue.splice(index, 1);
        console.log("newValue:", newValue);
        this.$set(this.data, item.name, newValue);
      } else {
        this.$set(this.data, name, "");
      }
    },

    onChange(value, m) {
      this.changeVal = value;

      if (!this.$utils.isEmpty(value) && m) {
        const [name, targetValue, action] = m;
        if (targetValue !== value && !action) {
          this.customHiddenNameList = [...this.customHiddenNameList, name];
        } else {
          this.customHiddenNameList = this.customHiddenNameList.filter(
            (val) => val !== name
          );
        }
        // console.log(
        //   "name:",
        //   name,
        //   "targetValue:",
        //   targetValue,
        //   "value:",
        //   value,
        //   "action:",
        //   action,
        //   this.customHiddenNameList
        // );
        // const nameItem = this.list.find((v) => v.name === name);
        // console.log("nameItem:", nameItem, targetValue);
        // const nameItemIndex = this.list.indexOf(nameItem);
        // console.log("nameItemIndex:", nameItemIndex);
        // this.$set(this.mutableFormList, nameItemIndex, {
        //   ...this.list[nameItemIndex],
        //   hidden: targetValue === value ? !!action : !action,
        // });
      }
    },

    // submitForm() {
    //   const goodsTypeList = localStorage.getItem("goodsTypeList");
    //   console.log("goodsTypeList", goodsTypeList);

    //   //城市和国家的数据
    //   const fromCountry = localStorage.getItem("fromCountry");
    //   const toCountry = localStorage.getItem("toCountry");
    //   if (this.selectLabel) {
    //     this.data.fromCountry = this.selectLabel;
    //   } else {
    //     this.data.fromCountry = fromCountry;
    //   }
    //   if (this.selectLabel) {
    //     this.data.toCountry = this.selectToLabel;
    //   } else {
    //     this.data.toCountry = toCountry;
    //   }
    //   //物流天数
    //   const period = Number(this.data.period);
    //   this.data.period = period;
    //   ///pricing/edit页面的数据处理
    //   if (
    //     this.data.logisticsLadderList &&
    //     this.$route.path == "/pricing/edit"
    //   ) {
    //     const multipliedPrices = this.data.logisticsLadderList.map((item) => ({
    //       ...item,
    //       price: Number((item.price * 7.255).toFixed(0)),
    //     }));
    //     this.data.logisticsLadderList = [...multipliedPrices];
    //     //价格处理
    //     this.data.price = this.channelPrice;
    //   } else if (this.$route.path == "/product/edit") {
    //     if (this.data.specialitems || goodsTypeList) {
    //       let specialItemString =
    //         typeof this.data.specialitems === "number"
    //           ? String(this.data.specialitems)
    //           : this.data.specialitems;
    //       this.data.specialitems = specialItemString;
    //       if (
    //         this.data.specialitems == "140" ||
    //         this.data.specialitems == "141" ||
    //         this.data.specialitems == "139"
    //       ) {
    //         let result =
    //           this.data.specialitems === "140" ||
    //           this.data.specialitems === "141"
    //             ? "139"
    //             : this.data.specialitems;
    //         this.data.specialitems = result;
    //       }
    //     } else {
    //       this.$message({
    //         message: this.$t("pricing.isRequiredGoodsType"),
    //         type: "warning",
    //       });
    //       return;
    //     }
    //   }

    //   this.$refs["ruleForm"].validate((valid, Object) => {
    //     console.log("Object:", Object);

    //     if (valid) {
    //       this.$emit("submit", this.data);
    //     } else {
    //       console.log("error submit!!");

    //       return false;
    //     }
    //   });
    // },

    submitForm() {
      //城市和国家的数据
      const fromCountry = localStorage.getItem("fromCountry");
      const toCountry = localStorage.getItem("toCountry");
      if (this.selectLabel) {
        this.data.fromCountry = this.selectLabel;
      } else {
        this.data.fromCountry = fromCountry;
      }
      if (this.selectLabel) {
        this.data.toCountry = this.selectToLabel;
      } else {
        this.data.toCountry = toCountry;
      }
      //物流天数
      const period = Number(this.data.period);
      this.data.period = period;
      ///pricing/edit页面的数据处理
      if (
        this.data.logisticsLadderList &&
        this.$route.path == "/pricing/edit"
      ) {
        const multipliedPrices = this.data.logisticsLadderList.map((item) => ({
          ...item,
          price: Number((item.price * 7.255).toFixed(0)),
        }));
        this.data.logisticsLadderList = [...multipliedPrices];
        //价格处理
        this.data.price = this.channelPrice;
      } else if (this.$route.path == "/product/edit") {
        if(Array.isArray(this.data.specialitems)){
            this.data.specialitems = String(this.data.specialitems[0]);
        }else if(typeof(this.data.specialitems) == 'number'){
            this.data.specialitems = String(this.data.specialitems);
        }
        
        if (
          this.data.specialitems == "140" ||
          this.data.specialitems == "141" ||
          this.data.specialitems == "139"
        ) {
          let result =
            this.data.specialitems === "140" || this.data.specialitems === "141"
              ? "139"
              : this.data.specialitems;
          this.data.specialitems = result;
        }
      }

      this.$refs["ruleForm"].validate((valid, Object) => {
        console.log("Object:", Object);

        if (valid) {
          this.$emit("submit", this.data);
        } else {
          console.log("error submit!!",this.data);
          return false;
        }
      });
    },

    resetFields() {
      this.$refs.ruleForm.resetFields();
    },

    resetAndSubmit(formName) {
      this.$refs[formName].resetFields();
      this.$emit("submit", this.data);
    },
  },
};
</script>
  
  <style lang="scss">
.el-form--label-top .el-form-item__label {
  padding: 0;
  margin: 0;
}
.el-select {
  display: block;
}
.form-item-flex {
  display: block;
  margin-top: 20px;

  .el-form-item__content {
    display: flex;

    .el-button {
      flex-grow: 1;
      max-width: 300px;
    }
  }
}
</style>
  