<template>
  <div class="freight-mode-box">
    <el-select
      v-if="pagePath"
      :placeholder="$t('common.selectPlaceholder')"
      autocomplete="autocomplete"
      :value="value"
      @change="getSelectDep"
    >
      <el-radio-group>
        <el-option
          v-for="(item, index) in departments"
          :label="item.label"
          :value="item.value"
          :key="index"
          style="width: 900px"
        ></el-option>
      </el-radio-group>
    </el-select>

    <el-select
      v-else
      :placeholder="$t('common.selectPlaceholder')"
      autocomplete="autocomplete"
      multiple
      collapse-tags
      :value="value"
      @change="getSelectDep"
    >
      <el-checkbox v-model="checked" @change="selectAll">{{
        $t("common.selectall")
      }}</el-checkbox>
      <el-option
        v-for="(item, index) in departments"
        :label="item.label"
        :value="item.value"
        :key="index"
      ></el-option>
    </el-select>
  </div>
</template>
    
<script>
export default {
  props: {
    value: {
      type: Array || Number,
      default() {
        return [] || 0;
      },
    },
    pagePath: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: false,
      radio: 3,
      departments: [
        {
          value: 103,
          label: this.$t("menu.battery"),
        },
        {
          value: 105,
          label: this.$t("menu.e-cigarette"),
        },
        {
          value: 106,
          label: this.$t("menu.textile"),
        },
        {
          value: 107,
          label: this.$t("menu.box"),
        },
        {
          value: 108,
          label: this.$t("menu.ups"),
        },
        {
          value: 109,
          label: this.$t("menu.SupportingBattery"),
        },
        {
          value: 110,
          label: this.$t("menu.noBattery"),
        },
        {
          value: 111,
          label: this.$t("menu.powerSupply"),
        },
        {
          value: 139,
          label: this.$t("menu.Cargo"),
        },
        {
          value: 203,
          label: this.$t("menu.capacitance"),
        },
        {
          value: 204,
          label: this.$t("menu.internalPower"),
        },
        {
          value: 205,
          label: this.$t("menu.powerDistribution"),
        },
        {
          value: this.$route.path == "/product/edit" ? 140 : 139,
          label: this.$t("menu.compressedClothesJeans"),
        },
        {
          value: this.$route.path == "/product/edit" ? 141 : 139,
          label: this.$t("menu.compressedClothesButtons"),
        },
      ],
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val.length === this.departments.length) {
          this.checked = true;
        }
      },
    },
    deep: true,
    immediate: true,
  },
  computed: {
    select: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        // let selectVal = 0;
        // if (val == this.$t("menu.battery")) {
        //   selectVal = '103';
        // } else if (val == this.$t("menu.e-cigarette")) {
        //   selectVal = '105';
        // } else if (val == this.$t("menu.textile")) {
        //   selectVal = '106';
        // } else if (val == this.$t("menu.box")) {
        //   selectVal = '107';
        // } else if (val == this.$t("menu.ups")) {
        //   selectVal = '108';
        // } else if (val == this.$t("menu.SupportingBattery")) {
        //   selectVal = '109';
        // } else if (val == this.$t("menu.noBattery")) {
        //   selectVal = '110';
        // } else if (val == this.$t("menu.powerSupply")) {
        //   selectVal = '111';
        // } else if (val == this.$t("menu.Cargo")) {
        //   selectVal = '139';
        // } else if (val == this.$t("menu.capacitance")) {
        //   selectVal = '203';
        // } else if (val == this.$t("menu.internalPower")) {
        //   selectVal = '204';
        // } else if (val == this.$t("menu.powerDistribution")) {
        //   selectVal = '205';
        // }
        // this.$emit("input", selectVal);
      },
    },
  },
  methods: {
    selectAll(val) {
      if (val) {
        this.select = this.departments.map((v) => v.value);
      } else {
        this.select = [] || String;
      }
    },
    getSelectDep(department) {
    //   if (department == '103') {
    //     this.select = this.$t("menu.battery");
    //   } else if (department == '105') {
    //     this.select = this.$t("menu.e-cigarette");
    //   } else if (department == '106') {
    //     this.select = this.$t("menu.textile");
    //   } else if (department == '107') {
    //     this.select = this.$t("menu.box");
    //   } else if (department == '108') {
    //     this.select = this.$t("menu.ups");
    //   } else if (department == '109') {
    //     this.select = this.$t("menu.SupportingBattery");
    //   } else if (department == '110') {
    //     this.select = this.$t("menu.noBattery");
    //   } else if (department == '111') {
    //     this.select = this.$t("menu.powerSupply");
    //   } else if (department == '139') {
    //     this.select = this.$t("menu.Cargo");
    //   } else if (department == '203') {
    //     this.select = this.$t("menu.capacitance");
    //   } else if (department == '204') {
    //     this.select = this.$t("menu.internalPower");
    //   } else if (department == '205') {
    //     this.select = this.$t("menu.powerDistribution");
    //   }
      this.select = department;
      if (department.length === this.departments.length) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.el-checkbox {
  text-align: right;
  width: 100%;
  padding-right: 10px;
}
</style>